import Vue from 'vue'
import VueRouter from 'vue-router'
import Default from '@/layouts/default/Default.vue'
import Home from '../views/Home.vue'

Vue.component('LayoutDefault', Default)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      layout: 'default'
    }
  },

  {
    path: '/photo',
    name: 'Photo',
    component: () => import(/* webpackChunkName: "about" */ '../views/Photo.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/coding',
    name: 'Coding',
    component: () => import(/* webpackChunkName: "about" */ '../views/Coding.vue'),
    meta: {
      layout: 'default'
    }
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  routes
})

export default router
